import {FormsModal} from '@mp/common/modals/FormsModal';
import {convertToCurrency} from '@mp/common/utils/number';
import React, {useMemo, useState} from 'react';
import {deleteExpenseService, updateExpenseService} from '../services';
import {t} from '../t.i18n';
import {Expense, ExpenseCategory, ExpensesByDate} from '../types';
import {resolveExpensesByDate} from '../utils';
import {getInputsData} from '../utils/getInputsData';
import {getNameByTypeId} from '../utils/getNameByTypeId';
import css from './MonthExpensesList.module.scss';

export function MonthExpensesList(props: {
    expenses: Array<Expense>;
    expenseCategories: Array<ExpenseCategory>;
    onItemChange: () => void;
}): JSX.Element {
    const [expenseToEdit, setExpenseToEdit] = useState<Expense>(null);
    const expensesByDate: Array<ExpensesByDate> = useMemo(() => resolveExpensesByDate(props.expenses), [props.expenses]);

    return (
        <div className={css.list}>
            {expensesByDate.map((entry) => (
                <div key={entry.date}>
                    <div className={css.date}>
                        {entry.date} ({convertToCurrency(entry.value, true)})
                    </div>
                    <div>
                        {entry.expenses.map((ex) => (
                            <div key={ex.id} className={css.row} onDoubleClick={() => setExpenseToEdit(ex)}>
                                <div>{getNameByTypeId(ex.typeId, props.expenseCategories)}</div>
                                <div>{convertToCurrency(ex.value, true)}</div>
                                <div>{ex.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {expenseToEdit && (
                <FormsModal<Expense>
                    title={t.editWindowTitle}
                    mode="update"
                    inputs={getInputsData(expenseToEdit, props.expenseCategories)}
                    handleClose={() => setExpenseToEdit(null)}
                    onSendForm={(object) => updateExpenseService(object).then(({success}) => onServiceCompleted(success))}
                    onDeleteClick={(id) => deleteExpenseService(id).then(({success}) => onServiceCompleted(success))}
                />
            )}
        </div>
    );

    function onServiceCompleted(success: boolean): void {
        if (success) {
            setExpenseToEdit(null);
            props.onItemChange();
        }
    }
}
