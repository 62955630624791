const REPLACE_POLISH_CHARS_MAP: Map<string, string> = new Map<string, string>([
    ['\u00A0', ' '],
    ['ą', 'a'],
    ['Ą', 'A'],
    ['ć', 'c'],
    ['Ć', 'C'],
    ['ę', 'e'],
    ['Ę', 'E'],
    ['ł', 'l'],
    ['Ł', 'L'],
    ['ń', 'n'],
    ['Ń', 'N'],
    ['ó', 'o'],
    ['Ó', 'O'],
    ['ś', 's'],
    ['Ś', 'S'],
    ['ź', 'z'],
    ['Ź', 'Z'],
    ['ż', 'z'],
    ['Ż', 'Z']
]);

export function isEmptyString(text: string): boolean {
    return text == null || text === '';
}

export function isNotEmptyString(text: string): boolean {
    return !isEmptyString(text);
}

export function zeroPrefix(n: number | string): string {
    if (Number(n) < 10) {
        return '0' + n;
    }
    return '' + n;
}

export function replaceToNonBreakingSpaces(text: string): string {
    const LETTER_TO_REPLACE: Array<string> = ['a', 'i', 'o', 'u', 'w', 'z'];
    LETTER_TO_REPLACE.forEach((letter) => {
        text = text.replaceAll(` ${letter} `, ` ${letter}\u00A0`);
    });

    return text;
}

export function replacePolishChars(text: string): string {
    REPLACE_POLISH_CHARS_MAP.forEach((value, key) => {
        text = text.replace(key, value);
    });
    return text;
}

export function isPositiveNumber(text: string): boolean {
    const regex: RegExp = new RegExp('^\\d+$');
    return regex.test(text);
}

export function toLowerCaseAndReplacePolishChars(text: string): string {
    return replacePolishChars(text.toLowerCase());
}

export function normalizeString(text): string {
    if (text == null) {
        return text;
    }
    return toLowerCaseAndReplacePolishChars(text)
        .replace(/\s/g, '-')
        .replace(/[^a-zA-Z0-9/-]+/g, '');
}

export function join(...args: Array<string>): string {
    const result: string[] = [];
    args.forEach((arg) => {
        if (arg) {
            result.push(arg);
        }
    });
    return result.join(' ');
}

join('as', 'asd', 'asd');
