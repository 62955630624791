import {PatchServiceResponse, PostServiceResponse, PutServiceResponse, service} from '@mp/common/api';
import {toDate} from '@mp/common/utils/converters';
import {Gift, GiftDto, GiftStatus} from '../types';

const SERVICE_NAME: string = 'gifts';

export async function loadGiftsService(): Promise<Array<Gift>> {
    return service.get<Array<GiftDto>>(SERVICE_NAME).then(({data}) => {
        return data.map((entry: GiftDto): Gift => {
            const date: Date | null = toDate(entry.date);
            return {
                ...entry,
                date,
                timestamp: date?.getTime() ?? null,
                status: entry.status as GiftStatus
            };
        });
    });
}

export async function addGiftService(data: GiftDto): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data});
}

export async function updateGiftService(data: GiftDto): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}

export async function updateGiftStatusService(data: Pick<GiftDto, 'id' | 'status'>): Promise<PatchServiceResponse> {
    return service.patch(SERVICE_NAME, {data});
}
