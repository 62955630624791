import {Icon, SvgButton} from '@mp/common/svg';
import {Router} from '@mp/route';
import React from 'react';
import {Link} from 'react-router-dom';
import styles from './ExpensesNavigation.module.scss';

export function ExpensesNavigation({year, currentYear}: {year: number; currentYear: number}): JSX.Element {
    return (
        <div className={styles.navigation}>
            <NavigationButton year={year} currentYear={currentYear} isNext={false} />
            <span>{year}</span>
            <NavigationButton year={year} currentYear={currentYear} isNext={true} />
        </div>
    );
}

interface NavigationButtonProps {
    readonly year: number;
    readonly currentYear: number;
    readonly isNext: boolean;
}

function NavigationButton(props: NavigationButtonProps): JSX.Element {
    const {year, currentYear, isNext} = props;
    const icon: Icon = isNext ? Icon.ChevronRight : Icon.ChevronLeft;
    const value: number = isNext ? 1 : -1;

    if (isNext) {
        if (year >= currentYear) {
            return <div className={styles.navBtn} />;
        }
    } else {
        if (year <= 2018) {
            return <div className={styles.navBtn} />;
        }
    }

    return (
        <Link to={Router.getUrlToApartmentExpensesPage({year: year + value})}>
            <SvgButton icon={icon} color="black" />
        </Link>
    );
}
