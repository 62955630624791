import {CommonContext} from '@mp/common/context';
import {Icon, SvgButton} from '@mp/common/svg';
import {toDateString} from '@mp/common/utils/converters';
import {convertToCurrency} from '@mp/common/utils/number';
import {join} from '@mp/common/utils/string';
import classNames from 'classnames';
import React, {useContext, useMemo} from 'react';
import {GiftsPageContext} from '../context/GiftsPage.context';
import {updateGiftStatusService} from '../services/gifts.services';
import {Gift, GiftStatus, Person} from '../types';
import {getAllGiftStatuses, getStatusColor, getStatusName} from '../utils/giftStatus';
import css from './Gift.module.scss';

export function GiftComponent(props: {gift: Gift; onEditClick: (gift: Gift) => void}): JSX.Element {
    const {gift, onEditClick} = props;
    const {people} = useContext(GiftsPageContext);
    const {isEditMode} = useContext(CommonContext).context;
    const {id, name, status, date, personId, price} = gift;

    const personName: string = useMemo((): string => {
        const person: Person = people.find(({id}) => id === personId) ?? ({} as Person);
        return join(person.firstName, person.lastName);
    }, [people]);

    return (
        <div key={id} className={css.item} style={{backgroundColor: getStatusColor(status)}}>
            <div className={css.row}>
                <div>
                    {isEditMode && <SvgButton className={css.icon} icon={Icon.Pencil} onClick={() => onEditClick(gift)} />}
                    <span>{personName}</span>
                </div>
                <div>{toDateString(date)}</div>
            </div>
            <div className={css.row}>
                <div>{name || '-'}</div>
                <div className={css.price}>{price ? convertToCurrency(price, true) : '-'}</div>
            </div>
            <GiftStatusComponent gift={gift} isEditMode={isEditMode} />
        </div>
    );
}

function GiftStatusComponent({gift, isEditMode}: {gift: Gift; isEditMode: boolean}): JSX.Element {
    const {reloadData} = useContext(GiftsPageContext);
    const handleStatusClick = (newStatus: GiftStatus): void => {
        if (isEditMode) {
            updateGiftStatusService({id: gift.id, status: newStatus}).then(({success}) => {
                if (success) {
                    reloadData();
                }
            });
        }
    };

    return (
        <div className={css.statusContainer}>
            {isEditMode ? (
                getAllGiftStatuses().map((gStatus) => (
                    <div
                        key={gStatus}
                        className={classNames(css.status, {[css.statusSelected]: gStatus === gift.status, [css.statusEdit]: isEditMode})}
                        onDoubleClick={() => handleStatusClick(gStatus)}
                    >
                        {getStatusName(gStatus)}
                    </div>
                ))
            ) : (
                <div>{getStatusName(gift.status)}</div>
            )}
        </div>
    );
}
