import {service, PutServiceResponse, PostServiceResponse} from '@mp/common/api';
import {Wine} from '../types';

const WINE_SERVICE_NAME: string = 'wine';

export interface WinesServiceResult {
    wines: Array<Wine>;
    countrySuggestions: Array<string>;
    vineStockSuggestions: Array<string>;
    colorsSuggestions: Array<string>;
    drynessSuggestions: Array<string>;
    typeSuggestions: Array<string>;
    maxRating: number;
}
export function loadWinesService(): Promise<WinesServiceResult> {
    return service.get<WinesServiceResult>(WINE_SERVICE_NAME).then((res) => res.data);
}

export function addWineService(data: object): Promise<PostServiceResponse> {
    return service.post(WINE_SERVICE_NAME, {data});
}

export function editWineService(data: object): Promise<PutServiceResponse> {
    return service.put(WINE_SERVICE_NAME, {data});
}
