import {Expense, ExpensesByDate} from './types';

export function resolveExpensesByDate(expenses: Array<Expense>): Array<ExpensesByDate> {
    const expensesByDate: Array<ExpensesByDate> = [];

    expenses.forEach((expense) => {
        const foundItem: ExpensesByDate = expensesByDate.find((f) => f.date.substr(0, 10) === expense.date.substr(0, 10));

        if (foundItem) {
            foundItem.expenses.push(resolveExpense(expense));
        } else {
            expensesByDate.push({
                date: expense.date.substr(0, 10),
                expenses: [resolveExpense(expense)]
            });
        }
    });

    expensesByDate.forEach((e) => {
        let value: number = 0;
        e.expenses.forEach((ex) => {
            value += Number(ex.value);
        });
        e.value = value;
    });

    function resolveExpense(expense: Expense): Expense {
        return {
            id: expense.id,
            typeId: expense.typeId,
            date: expense.date,
            value: expense.value,
            description: expense.description
        };
    }

    return expensesByDate;
}
