import {service, PutServiceResponse, PostServiceResponse} from '@mp/common/api';
import {PaymentRow} from '../types';
import {calculateExpenses} from '../utils/calculateExpenses';

const SERVICE_NAME: string = 'apartmentExpenses';

interface LoadApartmentExpensesResult {
    previousExpense: PaymentRow;
    expenses: Array<PaymentRow>;
}

const cache: Map<number, LoadApartmentExpensesResult> = new Map();

export function loadApartmentExpensesService(year: number): Promise<LoadApartmentExpensesResult> {
    if (cache.get(year)) {
        return Promise.resolve(cache.get(year));
    }

    return service.get<LoadApartmentExpensesResult>(SERVICE_NAME, {data: {year}}).then(({data}) => {
        data.expenses = data.expenses.map((row, index, expenses) => ({
            ...row,
            calculatedTotalCost: calculateExpenses(expenses[index], expenses[index - 1] ?? data.previousExpense)?.totalCost ?? null
        }));
        cache.set(year, data);
        return data;
    });
}

export function addApartmentExpensesService(): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data: undefined});
}

export function updateApartmentExpensesService(data: object): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}
