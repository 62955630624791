import {service} from '@mp/common/api';
import {GiftGroup} from '../types';

type GiftGroupDto = GiftGroup;

const SERVICE_NAME: string = 'gifts.groups';

export async function loadGroupsService(): Promise<Array<GiftGroup>> {
    return service.get<Array<GiftGroupDto>>(SERVICE_NAME).then(({data}) => data);
}
