import './_filesPage.scss';
import {FileInput} from '@mp/common/components/file-input/ui/FileInput';
import {CommonContext} from '@mp/common/context';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {Icon, SvgButton} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import {toDateString} from '@mp/common/utils/converters';
import React, {useContext} from 'react';
import {Loading} from '../../components/loading';
import {ORIGIN} from '../../global';
import {deleteFileService, loadFilesService, updateFileService} from './services';
import {FileInfo} from './types';
import {EditFileNameModal} from './ui/EditFileNameModal';
import {getFileSize} from './utils/getFileSize';

const MP_FILES_PAGE = 'mp-files-page';
const MP_FILES_PAGE_HEADER = `${MP_FILES_PAGE}-header`;
const MP_FILES_PAGE_FILES_CONTAINER = `${MP_FILES_PAGE}-files-container`;
const MP_FILES_PAGE_FILE = `${MP_FILES_PAGE_FILES_CONTAINER}-file`;
const MP_FILE_NAME = `${MP_FILES_PAGE_FILE}-name`;
const MP_FILE_DETAILS = `${MP_FILES_PAGE_FILE}-details`;
const MP_FILE_DETAILS_LEFT = `${MP_FILE_DETAILS}-left`;
const MP_FILE_DETAILS_RIGHT = `${MP_FILE_DETAILS}-right`;

interface FilesPageState {
    allowedExtensions: Array<string>;
    maxFileSizeMB: number;
    directory: string;
    filesOnServer: Array<FileInfo>;
    folders: Array<string>;
    uploadPercentage: number;
    fileToDelete: FileInfo;
    fileToEdit: FileInfo;
    selectedFolder: string;
}

export function FilesPage(): JSX.Element {
    const {context} = useContext(CommonContext);

    return <FilesPageCC isEditMode={context.isEditMode} />;
}

interface FilesPageCCProps {
    isEditMode: boolean;
}

export class FilesPageCC extends React.Component<FilesPageCCProps, FilesPageState> {
    constructor(props: FilesPageCCProps) {
        super(props);

        this.uploadFile = this.uploadFile.bind(this);

        this.state = {
            allowedExtensions: [],
            folders: [],
            selectedFolder: null,
            directory: '',
            filesOnServer: null,
            uploadPercentage: null,
            maxFileSizeMB: 0,
            fileToEdit: null,
            fileToDelete: null
        };
    }

    public componentDidMount(): void {
        this.loadFiles();
    }

    private loadFiles() {
        loadFilesService(this.state.selectedFolder).then((response) =>
            this.setState({
                filesOnServer: response.files,
                directory: response.directory,
                uploadPercentage: null,
                fileToDelete: null,
                fileToEdit: null,
                maxFileSizeMB: response.maxFileSizeMB,
                allowedExtensions: response.allowedExtensions,
                folders: response.folders
            })
        );
    }

    private uploadFile(e: React.ChangeEvent<HTMLInputElement>): void {
        if (e?.target?.files?.length > 0) {
            const {files} = e.target;
            for (let i: number = 0; i < files.length; i++) {
                this.setState({uploadPercentage: 0});
                updateFileService(
                    files[i],
                    this.state.selectedFolder,
                    this.state.maxFileSizeMB,
                    this.state.allowedExtensions,
                    (percent) => this.setState({uploadPercentage: percent}),
                    () => this.setState({uploadPercentage: 100}, () => this.loadFiles())
                );
            }
        }
    }

    public render() {
        const {allowedExtensions, filesOnServer, folders, selectedFolder} = this.state;
        if (isEmpty(allowedExtensions) || filesOnServer === null) {
            return <Loading />;
        }
        const extensionsString: string = allowedExtensions.map((s) => `.${s}`).join(',');
        return (
            <div className={MP_FILES_PAGE}>
                <FileInput
                    accept={extensionsString}
                    multiple={true}
                    onChange={this.uploadFile}
                    uploadPercentage={this.state.uploadPercentage}
                />
                {selectedFolder && (
                    <div className={MP_FILES_PAGE_HEADER}>
                        <SvgButton icon={Icon.ArrowLeft} color="black" onClick={() => this.setSelectedFolder(undefined)} />
                        <span>{`Folder: ${selectedFolder}`}</span>
                    </div>
                )}
                <div className={MP_FILES_PAGE_FILES_CONTAINER}>
                    {folders.map((folder) => this.renderFile({displayName: folder} as FileInfo))}
                </div>
                <div className={MP_FILES_PAGE_FILES_CONTAINER}>{filesOnServer.map((file: FileInfo) => this.renderFile(file))}</div>
                {this.renderEditModalIfVisible()}
                {this.renderDeleteModalIfVisible()}
            </div>
        );
    }

    private setSelectedFolder(selectedFolder: string): void {
        this.setState({selectedFolder, filesOnServer: null}, () => this.loadFiles());
    }

    private renderEditModalIfVisible(): JSX.Element {
        const {fileToEdit} = this.state;
        if (fileToEdit == null) {
            return null;
        }

        return (
            <EditFileNameModal
                fileToEdit={fileToEdit}
                handleClose={() => this.setState({fileToEdit: null})}
                selectedFolder={this.state.selectedFolder}
                onUpdate={() => this.loadFiles()}
            />
        );
    }

    private renderDeleteModalIfVisible(): JSX.Element {
        const {fileToDelete} = this.state;
        if (fileToDelete == null) {
            return null;
        }

        const {displayName, fullName} = fileToDelete;

        return (
            <ConfirmModal
                title="Usuwanie pliku"
                question={`Czy na pewno chcesz usunąć "${displayName}"?`}
                handleConfirm={() =>
                    deleteFileService(fullName, this.state.selectedFolder).then(({success}) => success && this.loadFiles())
                }
                handleClose={() => this.setState({fileToDelete: null})}
            />
        );
    }

    private renderFile(file: FileInfo): JSX.Element {
        const fileLocation: string = `${this.state.directory}/${file.fullName}`;
        const downloadName: string = `${file.displayName}.${file.extension}`;
        const isFile: boolean = !!file.extension;

        return (
            <div key={file.fullName ?? file.displayName} className={MP_FILES_PAGE_FILE}>
                <div className={MP_FILE_NAME}>
                    <div>
                        {isFile ? (
                            <>
                                <a href={fileLocation} download={downloadName}>
                                    <SvgButton icon={Icon.CloudArrowDown} />
                                </a>
                                <a href={ORIGIN + fileLocation} target="_blank" rel="noreferrer">
                                    {file.displayName}
                                </a>
                            </>
                        ) : (
                            <div className="folder" onClick={() => this.setSelectedFolder(file.displayName)}>
                                <SvgButton icon={Icon.FolderOpen} />
                                <span>{file.displayName}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={MP_FILE_DETAILS}>
                    {isFile && (
                        <>
                            <div className={MP_FILE_DETAILS_LEFT}>
                                <div>{toDateString(new Date(file.uploaded), 'yyyy-mm-dd HH:MM:SS')}</div>
                                <div>
                                    {getFileSize(file.size)} ({file.extension})
                                </div>
                            </div>
                            {this.props.isEditMode && (
                                <div className={MP_FILE_DETAILS_RIGHT}>
                                    <SvgButton icon={Icon.Pencil} onClick={() => this.setState({fileToEdit: file})} />
                                    <SvgButton icon={Icon.Xmark} onClick={() => this.setState({fileToDelete: file})} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
