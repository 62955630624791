import {DeleteServiceResponse, PutServiceResponse, service} from '@mp/common/api';

const SERVICE_NAME: string = 'files.file';

export async function updateFileNameService(data: {newFileName: string; oldFileName: string; folder: string}): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}
export async function deleteFileService(fileName: string, folder: string): Promise<DeleteServiceResponse> {
    return service.delete(SERVICE_NAME, {data: {fileName, folder}});
}
