import {copyDate, currentDate as currentDateFn, getMonthName} from '@mp/common/utils/date';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {CalendarDate, CalendarEventType} from '../../../components/calendar/types';
import {resolveEventIcon} from '../../../components/calendar/utils/resolveEventTypeOptions';
import {getDaySpan} from '../../../packages/common/utils/dateSpanUtils';
import styles from './birthdayPage.module.scss';

interface ExtendedCalendarDate extends CalendarDate {
    isPastDate: boolean;
    daysTo: string;
    icon: string;
}

export function BirthdayPage({calendarDates}: {calendarDates: Array<CalendarDate>}): JSX.Element {
    const currentDate: Date = currentDateFn();
    const currentYear: number = currentDate.getFullYear();

    const dateToCurrentYear = (date: Date) => copyDate(date, {setYear: currentYear});
    const getDateString = (date: Date): string => `${date.getDate()} ${getMonthName(date)}`;
    const isPastDateFn = (date: Date): boolean => dateToCurrentYear(date).getTime() < currentDate.getTime();
    const daysToFn = (date: Date): string => {
        const span: number = getDaySpan(currentDate, dateToCurrentYear(date));
        if (span === 0) {
            return ' - dzisiaj';
        }
        return ` - za ${span} ${span === 1 ? 'dzień' : 'dni'}`;
    };

    const filteredDates: Array<ExtendedCalendarDate> = useMemo(() => {
        let occurrenceOfNotPastDate: number = 0;
        return calendarDates
            .filter(({type, name}): boolean => [CalendarEventType.BIRTHDAY, CalendarEventType.NAMEDAY].includes(type) || name == 'Ślub')
            .map((entry): ExtendedCalendarDate => {
                const isPastDate: boolean = isPastDateFn(entry.date);

                if (!isPastDate) {
                    occurrenceOfNotPastDate++;
                }

                return {
                    ...entry,
                    isPastDate,
                    icon: resolveEventIcon(entry.type) ?? '👰',
                    daysTo: occurrenceOfNotPastDate === 1 ? daysToFn(entry.date) : ''
                };
            });
    }, [calendarDates]);

    return (
        <div className={styles.page}>
            {filteredDates.map((entry) => (
                <div className={classNames(styles.item, {[styles.past]: entry.isPastDate})} key={entry.id}>
                    <div className={styles.flex}>
                        <div className={styles.icon}>{entry.icon}</div>
                        <div>
                            {getDateString(entry.date)}
                            {entry.daysTo && <span>{entry.daysTo}</span>}
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div>{entry.name}</div>
                        <div>{`(${currentYear - entry.date.getFullYear()})`}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}
