import React, {PropsWithChildren} from 'react';
import css from './SimpleTabNavigation.module.scss';

interface SimpleTabNavigationProps extends PropsWithChildren {
    name?: string;
}

export function SimpleTabNavigation(props: SimpleTabNavigationProps): JSX.Element {
    return <nav className={css.nav}>{props.children}</nav>;
}
