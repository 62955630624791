import {Context, createContext} from 'react';
import {Gift, GiftGroup, Person} from '../types';

export type GiftsPageContextType = {
    reloadData: () => void;
    people: Array<Person>;
    groups: Array<GiftGroup>;
    gifts: Array<Gift>;
};
export const GiftsPageContext: Context<GiftsPageContextType> = createContext({
    reloadData: null,
    people: [],
    groups: [],
    gifts: []
});
