import {lastElement, secondToLastElement} from '@mp/common/utils/array';
import {getMonthName} from '@mp/common/utils/date';
import {numberWithUnit} from '@mp/common/utils/unit';
import React from 'react';
import {t} from '../i18n';
import {PaymentRow} from '../types';
import {calculateExpenses} from '../utils/calculateExpenses';
import styles from './ExpensesSummary.module.scss';

export function ExpensesSummary(props: {expenses: Array<PaymentRow>; previousYearExpense: PaymentRow; showTitle?: boolean}): JSX.Element {
    const {expenses, showTitle = true} = props;
    const lastRow = lastElement(expenses);
    const secondToLastRow = secondToLastElement(expenses) ?? props.previousYearExpense;
    if (secondToLastRow == null) {
        return <div />;
    }

    const {usedEnergy, usedColdWater, heatCost, energyCost, coldWaterCost, totalCost} = calculateExpenses(lastRow, secondToLastRow);

    if (totalCost == null) {
        return <div>Uzupełnij wszystkie dane za ostatni miesiąc</div>;
    }

    const date: Date = new Date(lastRow.dateString);

    if (expenses.length >= 2) {
        return (
            <div className={styles.main}>
                {showTitle && <div className={styles.title}>{`${getMonthName(date)} ${date.getFullYear()}`}</div>}
                <table className="summary">
                    <tbody>
                        <tr>
                            <td>{t.energy.toLowerCase()}</td>
                            <td>{numberWithUnit(lastRow.energyCost, 'zł/kWh', 2)}</td>
                            <td>{numberWithUnit(usedEnergy, 'kWh', 2)}</td>
                            <td>{numberWithUnit(energyCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>{t.coldWater.toLowerCase()}</td>
                            <td>{numberWithUnit(lastRow.coldWaterCost, 'zł/m3', 2)}</td>
                            <td>{numberWithUnit(usedColdWater, 'm3', 3)}</td>
                            <td>{numberWithUnit(coldWaterCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>{t.heat.toLowerCase()}</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(heatCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>fundusz remontowy</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(lastRow.renovationFundCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>śmieci</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(lastRow.trashCost, 'zł', 2)}</td>
                        </tr>
                    </tbody>
                </table>
                <div className={styles.sum}>
                    <div>do zapłaty</div>
                    <div>{numberWithUnit(totalCost, 'zł', 2)}</div>
                </div>
                <div className={styles.um}>
                    <div className={styles.umHeader}>stan liczników:</div>
                    <div className={styles.umContent}>
                        <Row name={t.energy} value={lastRow.energy} />
                        <Row name={t.coldWater} value={lastRow.coldWater} />
                        <Row name={t.hotWater} value={lastRow.hotWater} />
                    </div>
                </div>
            </div>
        );
    }
}

function Row({name, value}: {name: string; value: number}): JSX.Element {
    return (
        <div className={styles.umRow}>
            <div>{`${name.toLowerCase()}:`}</div>
            <div>{value}</div>
        </div>
    );
}
