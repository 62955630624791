import {service, PutServiceResponse, PostServiceResponse} from '@mp/common/api';

const SERVICE_NAME: string = 'calendar.date';

export function addDateService(data: object): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data});
}

export function setDoneDateService(params: {id: number; doneDate: string}): Promise<PutServiceResponse> {
    const {id, doneDate} = params;
    return service.put(SERVICE_NAME, {data: {id, doneDate}});
}

export function updateDateService(data: object): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}
