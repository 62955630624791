import {service, PutServiceResponse, PostServiceResponse, PatchServiceResponse} from '@mp/common/api';
import {SeriesDTO} from '../types';

const SERVICE_NAME: string = 'series';

export function insertSeriesService(data: SeriesDTO): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data});
}

export function updateSeriesService(data: SeriesDTO): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}

export function updateEpisodeService(data: {id: number; episode: number}): Promise<PatchServiceResponse> {
    return service.patch(SERVICE_NAME, {data});
}

export function updateEpisodeNextAirDateService(id: number, nextAirDate: string): Promise<PatchServiceResponse> {
    return service.patch(SERVICE_NAME, {data: {id, nextAirDate}});
}
