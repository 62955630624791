import {service, PutServiceResponse, PostServiceResponse, PatchServiceResponse} from '@mp/common/api';
import {AxiosResponse} from 'axios';
import {WishItem} from '../types';
import {WishItemDTO} from './types';

const WISH_LIST_SERVICE_NAME: string = 'wishList.item';

export function loadWishItemsService(): Promise<Array<WishItem>> {
    return service.get<Array<WishItemDTO>>(WISH_LIST_SERVICE_NAME).then((result: AxiosResponse<Array<WishItemDTO>>) => result.data);
}

export function insertWishItemService(categoryId: number): Promise<PostServiceResponse> {
    return service.post(WISH_LIST_SERVICE_NAME, {data: {categoryId}});
}

export function updateWishItemService(item: WishItem): Promise<PutServiceResponse> {
    return service.put(WISH_LIST_SERVICE_NAME, {data: item});
}

export function updateWishItemBoughtService(item: WishItem): Promise<PatchServiceResponse> {
    return service.patch(WISH_LIST_SERVICE_NAME, {data: {id: item.id}});
}
