import {SimpleTabNavigation, TabAnchor} from '@mp/common/components/tab-navigation/ui';
import {RouteName} from '@mp/route';
import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Loading} from '../../../components/loading';
import {GiftsPageContext} from '../context/GiftsPage.context';
import {loadGiftsService} from '../services/gifts.services';
import {loadGroupsService} from '../services/giftsGroups.services';
import {loadPeopleService} from '../services/giftsPeople.services';
import {Gift, GiftGroup, Person} from '../types';
import css from './GiftsPage.module.scss';

export function GiftsPage(): JSX.Element {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [people, setPeople] = useState<Array<Person>>();
    const [groups, setGroups] = useState<Array<GiftGroup>>();
    const [gifts, setGifts] = useState<Array<Gift>>();

    const loadData = () => {
        setIsLoading(true);
        Promise.all([loadPeopleService(), loadGroupsService(), loadGiftsService()])
            .then(([_peoples, _groups, _gifts]) => {
                setPeople(_peoples);
                setGroups(_groups);
                setGifts(_gifts);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => loadData(), []);

    return (
        <div className={css.page}>
            <SimpleTabNavigation>
                <TabAnchor name="Prezenty" href={RouteName.GIFTS} />
                <TabAnchor name="Osoby" href={RouteName.GIFTS_PEOPLE} />
            </SimpleTabNavigation>
            <div className={css.content}>
                <GiftsPageContext.Provider value={{people, groups, gifts, reloadData: loadData}}>
                    {isLoading ? <Loading /> : <Outlet />}
                </GiftsPageContext.Provider>
            </div>
        </div>
    );
}
