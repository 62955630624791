import {SvgIcon} from '@mp/common/svg';
import React from 'react';
import {Link} from 'react-router-dom';
import {TileItemParams} from '../../../components/header/tileItems';
import styles from './TileItem.module.scss';

export function TileItem(params: TileItemParams): JSX.Element {
    const {title, svgIcon, route} = params;

    if (route.startsWith('http://') || route.startsWith('https://')) {
        return (
            <a className={styles.item} href={route} key={svgIcon}>
                <SvgIcon icon={svgIcon} />
                <div>{title}</div>
            </a>
        );
    }
    return (
        <Link className={styles.item} to={route} key={svgIcon}>
            <SvgIcon icon={svgIcon} />
            <div>{title}</div>
        </Link>
    );
}
