import {PostServiceResponse, service} from '@mp/common/api';
import {toDate} from '@mp/common/utils/converters';
import {Person} from '../types';

type PersonDto = Omit<Person, 'birthday'> & {birthday: string};

const SERVICE_NAME: string = 'gifts.people';

export async function loadPeopleService(): Promise<Array<Person>> {
    return service.get<Array<PersonDto>>(SERVICE_NAME).then(({data}) => {
        return data.map((entry: PersonDto): Person => {
            return {
                ...entry,
                birthday: toDate(entry.birthday)
            };
        });
    });
}

export async function addPersonService(data: Person): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data});
}
