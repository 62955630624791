import {service, PutServiceResponse, PostServiceResponse} from '@mp/common/api';
import {CostEstimateSection} from '../types';

const SERVICE_NAME = 'costsEstimateSections';

export function addCostsEstimateSectionService(data: Omit<CostEstimateSection, 'items'>): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data});
}

export function editCostsEstimateSectionService(data: Omit<CostEstimateSection, 'items'>): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}
