import {getEnumValues} from '@mp/common/utils/enum';
import {GiftStatus} from '../types';

interface GiftStatusEntry {
    value: GiftStatus | null;
    name: string;
    color: string;
    defaultSelected: boolean;
}

const MAP: Map<GiftStatus, GiftStatusEntry> = new Map([
    [null as GiftStatus, {value: null, name: 'Wybieranie', color: '#ea5959', defaultSelected: true}],
    [GiftStatus.ORDERED, {value: GiftStatus.ORDERED, name: 'Zamówiono', color: '#f4c46b', defaultSelected: true}],
    [GiftStatus.BOUGHT, {value: GiftStatus.BOUGHT, name: 'Odebrano', color: '#f4f496', defaultSelected: true}],
    [GiftStatus.DELIVERED, {value: GiftStatus.DELIVERED, name: 'Wręczono', color: '#71d971', defaultSelected: false}]
]);

export function getStatusName(status: GiftStatus): string {
    return MAP.get(status)?.name;
}

export function getStatusColor(status: GiftStatus): string {
    return MAP.get(status)?.color;
}

export function getAllGiftStatuses(): Array<GiftStatus> {
    return [null, ...getEnumValues(GiftStatus)];
}

export function getAllGiftStatusesEntries(): Array<GiftStatusEntry> {
    return Array.from(MAP.values());
}
