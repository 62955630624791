import {toDateString} from '@mp/common/utils/converters';
import {addDay, isCurrentDay, resolveDayName} from '@mp/common/utils/date';
import {Router} from '@mp/route';
import {TabItem} from '../../../components/tabs-navigation/ui/TabsNavigation';

export function getNavigationTabs(date: Date, isFakeMode: boolean = true): Array<TabItem> {
    const prevDate: Date = isCurrentDay(date) ? date : addDay(date, -1);
    const nextDate: Date = addDay(date, 1);

    const resolveLabelPrefix = (): string => {
        if (isCurrentDay(date)) {
            return 'Dzisiaj';
        }
        if (isCurrentDay(prevDate)) {
            return 'Jutro';
        }
        return resolveDayName(date);
    };

    const label: string = `${resolveLabelPrefix()}, ${toDateString(date, 'dd.mm')}`;

    if (isFakeMode) {
        return [
            {name: '<', selected: false, url: null},
            {name: label, selected: false, url: null},
            {name: '>', selected: false, url: null}
        ];
    }

    const showPreview: boolean = !isCurrentDay(date);

    return [
        {name: showPreview ? '<' : ' ', selected: false, url: !showPreview ? null : Router.getUrlToCinemaProgramPage({date: prevDate})},
        {name: label, selected: false, url: null},
        {name: '>', selected: false, url: Router.getUrlToCinemaProgramPage({date: nextDate})}
    ];
}
