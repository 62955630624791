import {service, PutServiceResponse} from '@mp/common/api';
import {IKnowSubCard} from '../types';

export function updateIKnowSubCardService(iKnowCard: IKnowSubCard): Promise<PutServiceResponse> {
    const {id, question, clue_1, clue_2, clue_3, answer} = iKnowCard;

    return service.put('games.IKnowCardUpdate', {
        data: {id: Number(id), question, answer, clue_1: addDot(clue_1), clue_2: addDot(clue_2), clue_3: addDot(clue_3)}
    });
}

function addDot(text: string): string {
    if (text.length > 5 && text.charAt(text.length - 1) !== '.') {
        text += '.';
    }
    return text;
}
