/* eslint-disable no-console */
import {addLog} from '../../global';
import {LogLevel} from './types';

export class Logger {
    private constructor() {}

    public static error(message: string): void {
        addLog({message, level: LogLevel.Error});
        console.error(message);
    }

    public static wholeError(error: unknown, data?: unknown): void {
        addLog({message: JSON.stringify(error), level: LogLevel.Error});
        console.error(error, data);
    }

    public static warn(message: string): void {
        addLog({message, level: LogLevel.Warning});
        console.warn(message);
    }

    public static info(message: string): void {
        addLog({message, level: LogLevel.Info});
        console.info(message);
    }
}
