import './_costsEstimatePage.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {CommonContext} from '@mp/common/context';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {convertToCurrency} from '@mp/common/utils/number';
import {Router} from '@mp/route';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Loading} from '../../../components/loading';
import {isAdmin} from '../../../global';
import {
    addCostsEstimateItemService,
    updateCostsEstimateItemService,
    loadCostsEstimateSectionAndItemsService
} from '../services/costsEstimateItems.service';
import {addCostsEstimateSectionService, editCostsEstimateSectionService} from '../services/costsEstimateSections.service';
import {CostEstimateItem, CostEstimateSection, CostEstimateWarning} from '../types';
import {getItemInputsData, getSectionInputsData} from '../utils/getInputsData';
import {CostsEstimateSection} from './CostsEstimateSection';
import {CostsEstimateWarnings} from './CostsEstimateWarnings';

interface CostEstimatePageProps {
    listId: number;
    section: string;
}

export function CostsEstimatePage({listId, section}: CostEstimatePageProps): JSX.Element {
    const {context} = useContext(CommonContext);
    const [forceUpdate, setForceUpdate] = useState<object>();
    const [sections, setSections] = useState<Array<CostEstimateSection>>();
    const [addItem, setAddItem] = useState<boolean>(null);
    const [editItem, setEditItem] = useState<CostEstimateItem>(null);
    const [addSection, setAddSection] = useState<boolean>(null);
    const [editSection, setEditSection] = useState<CostEstimateSection>(null);
    const [warnings, setWarnings] = useState<Array<CostEstimateWarning>>([]);

    useEffect(() => {
        loadCostsEstimateSectionAndItemsService({costEstimateId: Number(listId)}).then((data) => setSections(data));
    }, [forceUpdate]);

    useEffect(() => {
        if (sections) {
            const allWarnings: Array<CostEstimateWarning> = [];
            sections.forEach((section) => {
                section.items.forEach((item) => {
                    if (item.warning) {
                        allWarnings.push(item.warning);
                    }
                });
            });
            setWarnings(allWarnings);
        }
    }, [sections]);

    const totalEstimatedCost: number = useMemo((): number => {
        return sections?.map(({estimatedCost}) => estimatedCost).reduce((a, b) => a + b);
    }, [sections]);

    const totalRealCost: number = useMemo((): number => {
        return sections?.reduce((total, section) => {
            const sectionPrice = section.items.reduce((sectionTotal, item) => sectionTotal + item.pricePaid, 0);
            return total + sectionPrice;
        }, 0);
    }, [sections]);

    const totalRefundCost: number = useMemo((): number => {
        return sections?.reduce((total, section) => {
            const sectionPrice = section.items.reduce((sectionTotal, item) => sectionTotal + item.costRefund, 0);
            return total + sectionPrice;
        }, 0);
    }, [sections]);

    if (sections == null) {
        return <Loading />;
    }

    const {isEditMode} = context;

    const toCurrency = (value: number): string => convertToCurrency(value, true);

    const resetState = (): void => {
        setSections(null);
        setAddItem(null);
        setEditItem(null);
        setAddSection(null);
        setEditSection(null);
        setForceUpdate([]);
    };

    return (
        <div className="mp-costs-estimate-page">
            <SimpleToolBar
                backLink={Router.getUrlToCostsEstimate({})}
                name={section}
                onAddClick={isEditMode ? () => setAddSection(true) : null}
            />
            <div className="total-cost">{`Estymowany koszt: ${toCurrency(totalEstimatedCost)}`}</div>
            <div className="total-cost">{`Zapłacono: ${toCurrency(totalRealCost)}`}</div>
            <div className="total-cost">{`Zwrot kosztów: ${toCurrency(totalRefundCost)}`}</div>
            <CostsEstimateWarnings warnings={warnings} />
            {sections.map((section) => (
                <CostsEstimateSection
                    key={section.id}
                    section={section}
                    isEditMode={isEditMode}
                    onEditSection={setEditSection}
                    onEditItem={setEditItem}
                />
            ))}
            {isAdmin() && isEditMode && <Button title="Dodaj produkt" onClick={() => setAddItem(true)} />}
            {addSection && listId && (
                <FormsModal<CostEstimateSection>
                    title="Dodaj sekcję"
                    mode="add"
                    inputs={getSectionInputsData({costEstimateId: listId} as CostEstimateSection)}
                    handleClose={() => setAddSection(null)}
                    onSendForm={(data) =>
                        addCostsEstimateSectionService(data).then(({success}) => {
                            if (success) {
                                resetState();
                            }
                        })
                    }
                />
            )}
            {editSection && listId && (
                <FormsModal<CostEstimateSection>
                    title="Edytuj sekcję"
                    mode="update"
                    inputs={getSectionInputsData(editSection)}
                    handleClose={() => setEditSection(null)}
                    onSendForm={(data) =>
                        editCostsEstimateSectionService(data).then(({success}) => {
                            if (success) {
                                resetState();
                            }
                        })
                    }
                />
            )}
            {addItem && (
                <FormsModal<CostEstimateItem>
                    title="Dodaj produkt"
                    mode="add"
                    inputs={getItemInputsData()}
                    handleClose={() => setAddItem(null)}
                    onSendForm={(data) =>
                        addCostsEstimateItemService(data).then(({success}) => {
                            if (success) {
                                resetState();
                            }
                        })
                    }
                />
            )}
            {editItem && (
                <FormsModal<CostEstimateItem>
                    title="Edytuj produkt"
                    mode="update"
                    inputs={getItemInputsData(editItem)}
                    handleClose={() => setEditItem(null)}
                    onSendForm={(data) =>
                        updateCostsEstimateItemService(data).then(({success}) => {
                            if (success) {
                                resetState();
                            }
                        })
                    }
                />
            )}
        </div>
    );
}
