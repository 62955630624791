import {Button} from '@mp/common/components/button/ui/Button';
import {DateInput} from '@mp/common/components/date-input/ui/DateInput';
import {useNumberQueryParams, useQueryParams} from '@mp/common/hooks/useQueryParams';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {isEmpty, isNotEmpty} from '@mp/common/utils/array';
import {toDate, toDateString} from '@mp/common/utils/converters';
import {convertToCurrency} from '@mp/common/utils/number';
import {Router} from '@mp/route';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MultiOption} from '../../../components/multi-select/types';
import {MultiSelect} from '../../../components/multi-select/ui/MultiSelect';
import {isAdmin} from '../../../global';
import {GiftsPageContext} from '../context/GiftsPage.context';
import {updateGiftService} from '../services/gifts.services';
import {Gift, GiftDto, GiftStatus} from '../types';
import {getAllGiftStatusesEntries} from '../utils/giftStatus';
import {GiftComponent} from './Gift';
import css from './GiftsList.module.scss';

const SPLIT_VALUE: string = '|';
const NULL_STRING: string = 'null';

export function GiftsList(): JSX.Element {
    const {gifts, reloadData} = useContext(GiftsPageContext);
    const [giftToEdit, setGiftToEdit] = useState<Gift>();
    const [filteredGift, setFilteredGift] = useState<Array<Gift>>();
    const navigate = useNavigate();

    const dateFrom: number = useNumberQueryParams('dateFrom');
    const dateTo: number = useNumberQueryParams('dateTo');
    const filtersQuery: string = useQueryParams('filters');

    const filters: Array<GiftStatus> = filtersQuery
        ?.split(SPLIT_VALUE)
        ?.map((entry) => (entry === NULL_STRING ? null : (entry as GiftStatus)));

    const totalPrice: number = useMemo((): number => {
        return (filteredGift ?? gifts)
            .map(({price}) => price)
            .filter(Boolean)
            .reduce((a, b) => a + b, 0);
    }, [filteredGift, gifts]);

    useEffect(() => {
        if (dateFrom || dateTo || isNotEmpty(filters)) {
            setFilteredGift(
                gifts
                    .filter(({timestamp}) => {
                        const cond1: boolean = dateFrom ? timestamp >= dateFrom : true;
                        const cond2: boolean = dateTo ? timestamp <= dateTo : true;
                        return cond1 && cond2;
                    })
                    .filter((entry) => filters.includes(entry.status))
            );
        } else {
            setFilteredGift(null);
        }
    }, [dateFrom, dateTo, filtersQuery]);

    useEffect(() => {
        if (isEmpty(filters)) {
            navigate(Router.getUrlToGifts({filters: [NULL_STRING, GiftStatus.BOUGHT, GiftStatus.ORDERED].join(SPLIT_VALUE)}));
        }
    }, []);

    if (filteredGift == null) {
        return null;
    }

    return (
        <div className={css.page}>
            <Filters dateFrom={dateFrom} dateTo={dateTo} filters={filters} />
            {(filteredGift ?? gifts).map((gift) => (
                <GiftComponent key={gift.id} gift={gift} onEditClick={(data) => setGiftToEdit(data)} />
            ))}
            {isAdmin() && <div>Całkowity koszt: {convertToCurrency(totalPrice, true)}</div>}
            {giftToEdit && (
                <FormsModal<GiftDto>
                    title="Aktualizuj prezent"
                    mode="update"
                    inputs={[
                        {id: 'id', displayName: '', type: 'hidden', defaultValue: giftToEdit.id},
                        {id: 'personId', displayName: '', type: 'hidden', defaultValue: giftToEdit.personId},
                        {id: 'name', displayName: 'Prezent', type: 'text', defaultValue: giftToEdit.name},
                        {id: 'date', displayName: 'Data wręczenia', type: 'date', defaultValue: giftToEdit.date},
                        {id: 'price', displayName: 'Cena', type: 'text-number', defaultValue: giftToEdit.price}
                    ]}
                    handleClose={() => setGiftToEdit(null)}
                    onSendForm={(data) =>
                        updateGiftService(data).then(({success}) => {
                            if (success) {
                                reloadData();
                            }
                        })
                    }
                />
            )}
        </div>
    );
}

function Filters(props: {dateFrom: number; dateTo: number; filters: Array<string>}): JSX.Element {
    const multiSelect = useRef<MultiSelect>();
    const dateInputFrom = useRef<HTMLInputElement>();
    const dateInputTo = useRef<HTMLInputElement>();
    const navigate = useNavigate();

    const handleFilterClick = (): void => {
        const newFilters: string = multiSelect.current
            .getValues()
            .map((entry) => (entry == null ? NULL_STRING : entry))
            .join(SPLIT_VALUE);

        navigate(
            Router.getUrlToGifts({
                dateFrom: toDate(dateInputFrom.current.value)?.getTime(),
                dateTo: toDate(dateInputTo.current.value)?.getTime(),
                filters: newFilters
            })
        );
    };

    const handleFilterResetClick = (): void => {
        dateInputFrom.current.value = '';
        dateInputTo.current.value = '';
        navigate(Router.getUrlToGifts({dateFrom: undefined, dateTo: undefined}));
    };

    const selectOptions: Array<MultiOption> = useMemo(() => {
        return getAllGiftStatusesEntries().map((entry): MultiOption => ({name: entry.name, value: entry.value}));
    }, []);

    return (
        <div className={css.filters}>
            <div className={css.multiSelect}>
                <MultiSelect ref={multiSelect} defaultValues={props.filters} options={selectOptions} disabled={null} />
            </div>
            <div className={css.dateFilters}>
                <DateInput reference={dateInputFrom} defaultValue={toDateString(props.dateFrom)} />
                <DateInput reference={dateInputTo} defaultValue={toDateString(props.dateTo)} />
                <div className={css.filtersButtons}>
                    <Button title="Filtruj" onClick={handleFilterClick} />
                    {(props.dateFrom || props.dateTo) && <Button title="Reset" onClick={handleFilterResetClick} />}
                </div>
            </div>
        </div>
    );
}
