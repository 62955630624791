import {service, PutServiceResponse, PostServiceResponse, PatchServiceResponse} from '@mp/common/api';
import {replaceToNonBreakingSpaces} from '@mp/common/utils/string';
import {ORIGIN} from '../../../global';
import {Recipe, RecipeCategory} from '../types';

const SERVICE_NAME: string = 'recipes';

interface LoadRecipesServiceResult {
    recipes: Array<Recipe>;
    categories: Array<RecipeCategory>;
}

export function loadRecipesService(): Promise<LoadRecipesServiceResult> {
    return service.get<LoadRecipesServiceResult>(SERVICE_NAME).then(({data}) => {
        addNonBreakingSpaces(data);
        return data;
    });

    function addNonBreakingSpaces(result: LoadRecipesServiceResult): void {
        result.recipes.forEach((recipe) => {
            recipe.name = replaceToNonBreakingSpaces(recipe.name);

            // todo remove
            recipe.image = recipe.image ? recipe.image : `${ORIGIN}/images/recipes/${recipe.id}.webp`;
        });
    }
}

export function addRecipeService(data: Recipe): Promise<PostServiceResponse> {
    return service.post(SERVICE_NAME, {data});
}

export function editRecipeService(data: Recipe): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data});
}

export function uploadImage(recipeId: number, imageBase64: string): Promise<PutServiceResponse> {
    return service.put(SERVICE_NAME, {data: {id: recipeId, base64: imageBase64}});
}

export function updateIsCompleted({id, isCompleted}: Pick<Recipe, 'id' | 'isCompleted'>): Promise<PatchServiceResponse> {
    return service.patch(SERVICE_NAME, {data: {id, isCompleted}});
}
