import './_cinemaProgramPage.scss';
import {Blink} from '@mp/common/components/blink/ui/Blink';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextArea} from '@mp/common/components/text-area/ui/TextArea';
import {useQueryParams} from '@mp/common/hooks/useQueryParams';
import {isEmpty, isNotEmpty} from '@mp/common/utils/array';
import {toDateString} from '@mp/common/utils/converters';
import {DATE_PARAM, Router} from '@mp/route';
import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {TabsNavigation} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {isAdmin} from '../../../global';
import {loadCinemaProgramService} from '../services';
import {addCacheCinemaProgramService} from '../services/cinameProgram.service';
import {MovieInfo} from '../types';
import {getNavigationTabs} from '../utils/getNavigationTabs';
import {resolveMovieId} from '../utils/resolveMovieTimeId';
import styles from './CinemaProgramPage.module.scss';
import {MovieInfoBox} from './MovieInfoBox';
import {MovieInfoBoxSkeleton} from './MovieInfoBoxSkeleton';
import {MovieInfoSingleSchedule, ScheduleModal} from './ScheduleModal';

interface CinemaProgramProps {
    date: Date;
    id: string;
}

export function CinemaProgramPage(): JSX.Element {
    const propsDate: string = useQueryParams(DATE_PARAM);
    const {movieId} = useParams<{movieId: string}>();

    if (!propsDate) {
        return <Navigate to={Router.getUrlToCinemaProgramPage({date: new Date()})} />;
    }
    return <CinemaProgramPageFC date={new Date(propsDate)} id={movieId} />;
}

function CinemaProgramPageFC({date, id}: CinemaProgramProps): JSX.Element {
    const dateToMovieInfosMap: MutableRefObject<Map<string, Array<MovieInfo>>> = useRef(new Map<string, Array<MovieInfo>>());
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [moviesInfo, setMoviesInfo] = useState<Array<MovieInfo>>([]);
    const [selectedMovieInfo, setSelectedMovieInfo] = useState<MovieInfoSingleSchedule>(null);

    useEffect(() => {
        setIsLoaded(false);
        const dateString: string = toDateString(date, 'yyyy-mm-dd');
        if (dateToMovieInfosMap.current.get(dateString)) {
            setMoviesInfo(dateToMovieInfosMap.current.get(dateString));
            setIsLoaded(true);
            return;
        }

        loadCinemaProgramService(date).then((movieInfos) => {
            if (isNotEmpty(movieInfos)) {
                dateToMovieInfosMap.current.set(dateString, movieInfos);
            }
            setMoviesInfo(movieInfos);
            setIsLoaded(true);
        });
    }, [date]);

    return (
        <div className="mp-cinema-program">
            <TabsNavigation tabs={getNavigationTabs(date, !isLoaded)} />
            {renderProgram()}
        </div>
    );

    function selectMovieBasedOnId(): void {
        if (id) {
            requestAnimationFrame(() => {
                const color: string = '#373737';
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.style.backgroundColor = color;
                    element.scrollIntoView();
                } else {
                    const timeScheduleElement: HTMLElement = document.querySelector(`[data-custom-stime="${id}"]`);
                    if (timeScheduleElement) {
                        timeScheduleElement.setAttribute('selected', String(true));
                        const movieEl: HTMLElement = document.getElementById(resolveMovieId(id));
                        if (movieEl) {
                            movieEl.scrollIntoView();
                            movieEl.style.backgroundColor = color;
                        }
                    }
                }
            });
        }
    }

    function renderProgram(): JSX.Element {
        if (!isLoaded) {
            return (
                <>
                    {[{}, {}, {}].map((value, index) => (
                        <MovieInfoBoxSkeleton key={index} />
                    ))}
                </>
            );
        }

        if (moviesInfo == null) {
            return (
                <>
                    <div className="empty-program">Nie udało się pobrać repertuaru</div>
                    {isAdmin() && <FailedToLoadAdmin date={date} />}
                </>
            );
        }

        if (isEmpty(moviesInfo)) {
            return <div className="empty-program">Brak seansów na wybrany dzień</div>;
        }

        selectMovieBasedOnId();

        return (
            <>
                {moviesInfo.map((movieInfo) => (
                    <MovieInfoBox
                        key={movieInfo.title}
                        movieInfo={movieInfo}
                        date={date}
                        onScheduleClick={(schedule) => setSelectedMovieInfo({movieInfo, schedule})}
                    />
                ))}
                <div className="mp-cinema-program-footer">
                    <Blink text="Wziąć słomki" />
                </div>
                {selectedMovieInfo && (
                    <ScheduleModal date={date} selectedMovieInfo={selectedMovieInfo} handleClose={() => setSelectedMovieInfo(null)} />
                )}
            </>
        );
    }
}

function FailedToLoadAdmin({date}: {date: Date}): JSX.Element {
    const textAreaProgram = useRef<HTMLTextAreaElement>(null);
    const dateString: string = toDateString(date, 'yyyy-mm-dd');
    const link: string = `https://multikino.pl/api/microservice/showings/cinemas/0004/films?showingDate=${dateString}T00:00:00&minEmbargoLevel=3&includesSession=true&includeSessionAttributes=true`;

    return (
        <div className={styles.failedLoadProgram}>
            <a href={link} target="_blank" rel="noreferrer">
                Repertuar
            </a>
            <TextArea reference={textAreaProgram} />
            <Button
                title="Wyślij"
                onClick={() => {
                    const value: string = textAreaProgram.current.value;
                    if (value) {
                        addCacheCinemaProgramService({date: dateString, program: value}).then(({success}) => {
                            if (success) {
                                window.location.reload();
                            }
                        });
                    }
                }}
            />
        </div>
    );
}
