const MILLISECONDS_IN_DAY: number = 1000 * 60 * 60 * 24;

export function getDaySpan(date1: Date, date2: Date): number {
    const daysSpan: number = Math.ceil(getMillisecondsSpan(date1, date2) / MILLISECONDS_IN_DAY);
    if (daysSpan === -0) {
        return 0;
    }
    return daysSpan;
}

export function getMillisecondsSpan(date1: Date, date2: Date): number {
    return date2.getTime() - date1.getTime();
}
