import {SelectOption} from '@mp/common/components/form-input/types';
import {CalendarEventType} from '../types';

const CALENDAR_EVENT_TYPE_TO_NAME_MAP: Map<CalendarEventType, Array<string>> = new Map([
    [CalendarEventType.BIRTHDAY, ['Urodziny', '🎂']],
    [CalendarEventType.NAMEDAY, ['Imieniny', '📜']],
    [CalendarEventType.EVENT, ['Wydarzenie']]
]);

export function resolveEventTypeOptions(): Array<SelectOption> {
    const elements: Array<SelectOption> = [];
    CALENDAR_EVENT_TYPE_TO_NAME_MAP.forEach((data: Array<string>, key: CalendarEventType) => {
        elements.push({name: data[0], value: key});
    });
    return elements;
}

export function resolveEventName(event: CalendarEventType, noEventName: boolean = false): string {
    if (noEventName && event === CalendarEventType.EVENT) {
        return '';
    }
    return CALENDAR_EVENT_TYPE_TO_NAME_MAP.get(event)?.[0] ?? event;
}

export function resolveEventIcon(event: CalendarEventType): string {
    return CALENDAR_EVENT_TYPE_TO_NAME_MAP.get(event)?.[1] ?? null;
}
