import {Logger} from '@mp/common/logger';
import {firstElement, isEmpty} from '@mp/common/utils/array';
import React, {useEffect, useRef, useState} from 'react';
import {Loading} from '../../../components/loading';
import {PaymentRow} from '../types';
import styles from './ExpensesChart.module.scss';

interface KeyValue {
    key: number;
    value: number;
}

export function ExpensesChart({expenses}: {expenses: Array<PaymentRow>}): JSX.Element {
    const [keyValues, setKeyValues] = useState<Array<KeyValue>>(null);
    const maxValue = useRef<number>(0);

    useEffect(() => {
        const keyValues: Array<KeyValue> = Array.from({length: 12}, (_, index) => index + 1).map((i): KeyValue => ({key: i, value: 0}));
        const firstMonthNumber: number = new Date(firstElement(expenses).dateString).getMonth();

        for (let i: number = 0; i < expenses.length; i++) {
            if (keyValues[firstMonthNumber + i]) {
                keyValues[firstMonthNumber + i].value = expenses[i].totalCost;
            } else {
                Logger.error('ExpensesChart: keyValues is not defined - error with data');
            }
        }
        maxValue.current = Math.max(...keyValues.map(({value}) => value));
        setKeyValues(keyValues);
    }, [expenses]);

    if (isEmpty(keyValues)) {
        return <Loading />;
    }

    const scale = (value: number): string => `${(100 / maxValue.current) * value}px`;

    return (
        <div className={styles.main} style={{height: scale(maxValue.current)}}>
            {keyValues.map((e) => (
                <div key={e.key} className={styles.bar} style={{height: scale(e.value)}} />
            ))}
        </div>
    );
}
