import {DeleteServiceResponse, service, PostServiceResponse, PatchServiceResponse} from '@mp/common/api';
import {Debt} from '../types';

const DEBTORS_SERVICE_NAME: string = 'debtors';

export function addDebtorsService(data: Debt): Promise<PostServiceResponse> {
    return service.post(DEBTORS_SERVICE_NAME, {data});
}

export function updateDebtorsService(data: Debt): Promise<PatchServiceResponse> {
    return service.patch(DEBTORS_SERVICE_NAME, {data});
}

export function deleteDebtorsService(id: number): Promise<DeleteServiceResponse> {
    return service.delete(DEBTORS_SERVICE_NAME, {data: {id}});
}
